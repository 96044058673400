import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import eventsSlice from "../features/event/eventSlice";
import userSlice from "../features/getAllUser/userSlice";
import recordingSlice from "../features/recording/recordingSlice";
import loginSlice from "../features/user/loginSlice/loginSlice";

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  event: eventsSlice,
  user: userSlice,
  recording: recordingSlice,
  login: loginSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
