import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import axios from 'axios'



export const getAllUser = createAsyncThunk('/user/content', async (data) => {
   
    try {
        if (data.searchText === undefined) {
            const response = await axios.get(`/get_all_users?page=${data.page}`, {});
            return response.data;
        } else {
            const response = await axios.get(`/get_all_users?search=${data.searchText}&page=${data.page}`, {});
            return response.data;
        }
    } catch (error) {
        return Promise.reject(new Error(error.response?.data?.message || 'No Result Found'))
    }
	
})

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoading: false,
        user : [],
        error: null,
    },
    reducers: {
    },

    extraReducers: {
		[getAllUser.pending]: state => {
			state.isLoading = true;
            state.error = null; 
		},
		[getAllUser.fulfilled]: (state, action) => {
			state.user = action.payload
			state.isLoading = false
           
		},
		[getAllUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload || 'No User Found';
		},
    }
})

export const {  deleteLead } = userSlice.actions

export default userSlice.reducer